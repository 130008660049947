import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import DarkMode from './DarkMode';

import styles from './index.module.css';

export default function MainMenu() {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { contentKey: { eq: "mainMenu" } }) {
        frontmatter {
          items {
            text
            url
          }
        }
      }
    }
  `);

  const { items } = data.markdownRemark.frontmatter;

  return (
    <nav>
      <ul className={styles.list}>
        {items.map(({ text, url }, index) => (
          <li key={index} className={styles.item}>
            <Link to={url} className={styles.link}>
              {text}
            </Link>
          </li>
        ))}
        <li className={styles.item}>
          <DarkMode />
        </li>
      </ul>
    </nav>
  );
}
