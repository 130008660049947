import React from 'react';
import { ThemeToggler } from 'gatsby-plugin-dark-mode';
import styles from './index.module.css';

export default function DarkMode() {
  return (
    <ThemeToggler>
      {({ theme, toggleTheme }) => (
        <label htmlFor="themeToggle">
          <input
            id="themeToggle"
            type="checkbox"
            className={styles.checkbox}
            onChange={(e) => toggleTheme(e.target.checked ? 'dark' : 'light')}
            checked={theme === 'dark'}
          />
          Dark Mode
        </label>
      )}
    </ThemeToggler>
  );
}
