import React from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styles from './index.module.css';

export default function SiteBranding() {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { contentKey: { eq: "seoSettings" } }) {
        frontmatter {
          title
        }
      }
    }
  `);

  const { title } = data.markdownRemark.frontmatter;

  return (
    <Link to="/" className={styles.text}>
      {title}
    </Link>
  );
}
