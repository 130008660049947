import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

export default function SEO({
  children,
  title,
  nameFirst,
  isHome,
  description,
}) {
  const data = useStaticQuery(graphql`
    {
      markdownRemark(frontmatter: { contentKey: { eq: "seoSettings" } }) {
        frontmatter {
          title
          homepageTitle
          homepageDescription
        }
      }
    }
  `);

  const { title: siteTitle } = data.markdownRemark.frontmatter;

  // Seems a bit gross? Prettier yells at me although it works.
  // Would rather pipe this in through the templates/index.js
  // Although can't seem to combine static query and dynamic query
  if (isHome) {
    const { homepageTitle, homepageDescription } =
      data.markdownRemark.frontmatter;

    title = homepageTitle;
    description = homepageDescription;
  }

  // Use nameFirst prop to move the site title/name first, before the pipe.
  let titleTemplate;

  if (nameFirst) {
    titleTemplate = `${siteTitle} | ${title}`;
  } else {
    titleTemplate = `${title} | ${siteTitle}`;
  }

  return (
    <Helmet titleTemplate={titleTemplate}>
      <html lang="en" />
      <title>{title}</title>
      {/* Meta Tags */}
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta charSet="utf-8" />
      {description && <meta name="description" content={description} />}
      {children}
    </Helmet>
  );
}
