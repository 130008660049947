import React from 'react';
import Header from '../Header';

import styles from './index.module.css';

export default function Layout({ children }) {
  return (
    <div className={styles.layout}>
      <Header />
      <div id="content" className="site-content">
        {children}
      </div>
      <hr />
    </div>
  );
}
