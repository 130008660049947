import React from 'react';
import Headroom from 'react-headroom';
import MainMenu from './MainMenu';
import SiteBranding from './SiteBranding';

import styles from './index.module.css';

export default function Header() {
  return (
    <Headroom>
      <header className={styles.header}>
        <div className={styles.branding}>
          <SiteBranding />
        </div>
        <div className={styles.menu}>
          <MainMenu />
        </div>
      </header>
    </Headroom>
  );
}
